import { getCompareProductsLength, useCompareStore, useProductsStore } from '../../hooks';
import { useTranslation } from '~/shared/utils';
import { useCallback, useMemo, useState } from 'react';
import { Button, Modal, Text, useTracking } from '~/shared/components';
import { StyledAlertContent } from './Style';
import { ProductModelDto } from '~/lib/data-contract';
import { Product } from '../../../model';
import { usePage } from '~/templates/pages';
import { arrangeProductData } from '../utils/arrangeProductData';
import { countryCodeKey, getCountryName } from '~/lib/twoLetterIsoCodeCountryList';
import { useIsClientSide } from '~/shared/hooks/useIsClientSide/useIsClientSide';

type CompareChildrenRenderFunctionProps = {
    isActive: boolean;
    defaultText: string;
    handleClick: () => void;
};

type Compare = {
    productId?: string;
    showText?: boolean;
    products?: Product[];
    product?: ProductModelDto;
    children?: (props: CompareChildrenRenderFunctionProps) => JSX.Element;
};

export const Compare = ({ productId, products: productsList = [], product, children }: Compare) => {
    const { setCompareProduct, removeCompareProduct, compareProducts, compareProductsIds } =
        useCompareStore();
    const { translate } = useTranslation();
    const isClientSide = useIsClientSide();
    const [showAlert, setShowAlert] = useState(false);
    const { trackFavoritesAddToComparison } = useTracking();

    const { market = 'int', culture = 'en' } = usePage();
    const locale = `${culture}-${market?.toUpperCase()}`;

    const { products } = useProductsStore();

    const productRef = productId ?? product?.productReference ?? '';

    const handleOnClick = useCallback(() => {
        const productToCompare = product
            ? (arrangeProductData(product, locale) as unknown as Product)
            : [...products, ...productsList].find((product) => product.productId === productRef);

        const numberOfProducts = getCompareProductsLength(compareProducts);

        const productIdx = compareProducts.findIndex(
            (product) => product?.productId === productToCompare?.productId,
        );

        if (productIdx !== -1) {
            removeCompareProduct(productIdx, productToCompare?.productId);
        } else if (productToCompare && numberOfProducts < 3) {
            setCompareProduct(productToCompare, locale);
            trackFavoritesAddToComparison({
                country: (market && getCountryName(market as countryCodeKey)) || market,
                product_name: productToCompare.displayName,
                product_id: productToCompare.productId,
                product_category:
                    productToCompare?.categoryPaths?.[0]?.pathFromRoot?.[0]?.displayName,
                product_category2:
                    productToCompare?.categoryPaths?.[0]?.pathFromRoot?.[1]?.displayName,
                product_category3:
                    productToCompare?.categoryPaths?.[0]?.pathFromRoot?.[2]?.displayName,
            });
        } else {
            setShowAlert(true);
        }
    }, [compareProducts, compareProductsIds, products, productRef]);

    const isActive = useMemo(() => {
        if (!isClientSide) return false;
        return compareProductsIds[productRef] ? true : false;
    }, [isClientSide, compareProductsIds, productRef]);

    const handleCloseAlert = () => {
        setShowAlert(false);
    };

    return (
        <>
            {children?.({
                isActive: isActive,
                defaultText: translate('Kompan.Commerce.CompareButton'),
                handleClick: handleOnClick,
            })}
            <Modal onDismiss={handleCloseAlert} open={showAlert}>
                <StyledAlertContent>
                    <div>
                        <Text variant="display5" as={'h2'}>
                            {translate('Kompan.Commerce.CompareAlertTitle')}
                        </Text>
                        <Text variant="body">
                            {translate('Kompan.Commerce.CompareAlertDescription')}
                        </Text>
                    </div>

                    <Button onClick={handleCloseAlert} variant="Red">
                        {translate('Kompan.Generic.Close', 'Close')}
                    </Button>
                </StyledAlertContent>
            </Modal>
        </>
    );
};
