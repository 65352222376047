import { useFavoriteStorage } from '../hooks';
import { BaseProductLayer, Button, useTracking } from '~/shared/components';
import { Product } from '../../model';
import { usePage } from '~/templates/pages';
import { countryCodeKey, getCountryName } from '~/lib/twoLetterIsoCodeCountryList';
import { Fragment, useMemo } from 'react';
import { Severity } from '~/shared/hooks/useNotification/useNotificationModel';
import { useNotification } from '~/shared/hooks/useNotification';
import { weakKey } from '~/shared/utils/jsx';
import { useFrame, useTranslation } from '~/shared/utils';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { ProductModelDto } from '~/lib/data-contract';
import { useIsClientSide } from '~/shared/hooks/useIsClientSide/useIsClientSide';

type FavoriteChildrenRenderFunctionProps = {
    isActive: boolean;
    handleClick: () => void;
};
type Favorite = {
    product?: Product | ProductModelDto;
    productId?: string;
    productName?: string;
    trackEventAdd?: () => void;
    trackEventRemove?: () => void;
    children: (props: FavoriteChildrenRenderFunctionProps) => JSX.Element;
};

export const Favorite = ({
    product,
    trackEventAdd,
    trackEventRemove,
    productId,
    productName,
    children,
}: Favorite) => {
    const { favorites, toggleFavorite } = useFavoriteStorage();
    const { trackFavoritesAddToFavorites, trackFavoritesRemoveFromFavorites } = useTracking();
    const isClientSide = useIsClientSide();
    const { market = 'int' } = usePage();
    const { data: frame } = useFrame();
    const router = useRouter();
    const { push, dismiss } = useNotification();

    const { translate } = useTranslation();

    const productProps: BaseProductLayer = useMemo(() => {
        if (!product) {
            return {
                product_id: productId,
                product_name: productName,
            };
        }
        if ('displayName' in product) {
            return {
                product_category: product?.categoryPaths?.[0]?.pathFromRoot?.[0]?.displayName,
                product_category2: product?.categoryPaths?.[0]?.pathFromRoot?.[1]?.displayName,
                product_category3: product?.categoryPaths?.[0]?.pathFromRoot?.[2]?.displayName,
                product_id: productId ?? product.productId,
                product_name: productName ?? product.displayName,
            };
        } else if ('categoryL1' in product) {
            return {
                product_category: product.categoryL1?.[0]?.name,
                product_category2: product.categoryL2?.[0]?.name,
                product_category3: product.categoryL3?.[0]?.name,
                product_id: productId ?? product.productReference,
                product_name: productName ?? product.productName,
            };
        }
        return {};
    }, [product, productName, productId]);

    const isActive = useMemo(() => {
        if (!isClientSide) return false;
        return favorites?.[`${productProps.product_id}`] ?? false;
    }, [favorites, productProps.product_id, isClientSide]);

    const url = frame?.staticLinks?.favoritePage?.url ?? '';

    const notificationRemoved = {
        text: (
            <>
                {translate('Kompan.Favorites.ToastMessageRemoveText').replace(
                    '[x]',
                    `${productProps?.product_name}`,
                )}
                <Button
                    variant={'Plain'}
                    size={'Inline'}
                    type={'button'}
                    onClick={() => {
                        handleAddToFavorites();
                        dismiss(weakKey(notificationRemoved));
                    }}
                >
                    {translate('Kompan.Favorites.ToastMessageRemoveButtonText')}
                </Button>
            </>
        ),
        severity: 'info' as Severity,
        duration: 5000,
    };

    const notificationAdded = {
        text: (
            <>
                {translate('Kompan.Favorites.ToastMessageAddText').replace(
                    '[x]',
                    `${productProps.product_name}`,
                )}
                <NextLink
                    aria-label={frame?.staticLinks?.favoritePage?.title}
                    href={router.asPath.includes('preview=true') ? `${url}?preview=true` : url}
                    prefetch={false}
                >
                    {translate('Kompan.Favorites.ToastMessageAddButtonText')}
                </NextLink>
            </>
        ),
        severity: 'info' as Severity,
        duration: 5000,
    };

    const handleRemoveFromFavorites = () => {
        toggleFavorite(productProps.product_id ?? '');
        if (trackEventRemove) {
            trackEventRemove();
        } else if (product) {
            trackFavoritesRemoveFromFavorites({
                country: (market && getCountryName(market as countryCodeKey)) || market,
                ...productProps,
            });
        }
        push(notificationRemoved);
    };

    const handleAddToFavorites = () => {
        toggleFavorite(productProps.product_id ?? '');
        if (trackEventAdd) {
            trackEventAdd();
        } else if (product) {
            trackFavoritesAddToFavorites({
                country: (market && getCountryName(market as countryCodeKey)) || market,
                ...productProps,
            });
        }
        push(notificationAdded);
    };

    const handleOnClick = () => {
        if (isActive) {
            handleRemoveFromFavorites();
        } else {
            handleAddToFavorites();
        }
    };

    const childComponent = useMemo(() => {
        if (!children) return <></>;
        return children({
            isActive: isActive,
            handleClick: handleOnClick,
        });
    }, [children, isActive, handleOnClick]);

    return <Fragment>{childComponent}</Fragment>;
};
