import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import { Favorite, FavoriteStore } from '../../model';
export const FAVORITE_STORAGE_KEY = 'kompanFavorites';
export const useFavoriteStorage = create(
    subscribeWithSelector<FavoriteStore>((set, get) => ({
        favorites:
            typeof window !== 'undefined'
                ? JSON.parse(localStorage.getItem(FAVORITE_STORAGE_KEY) || '{}')
                : {},
        toggleFavorite: (productId) => {
            const currentFavorites = get().favorites || {};
            const updatedFavorites: Favorite = {
                ...currentFavorites,
                [productId]: currentFavorites[`${productId}`] ? undefined : true,
            };
            if (typeof window !== 'undefined') {
                localStorage.setItem('kompanFavorites', JSON.stringify(updatedFavorites));
            }
            get().readFromStorage();
        },
        setAllToFavorite: (productIdList: string[]) => {
            const currentFavorites = get().favorites || {};
            const favorites = { ...currentFavorites };

            productIdList.forEach((productId) => {
                favorites[productId] = true;
            });

            if (typeof window !== 'undefined') {
                localStorage.setItem('kompanFavorites', JSON.stringify(favorites));
            }

            get().readFromStorage();
        },
        readFromStorage: () => {
            if (typeof window === 'undefined') {
                console.error(`Can't read favorites from storage on server side`);
            }
            try {
                const favorites = JSON.parse(localStorage.getItem(FAVORITE_STORAGE_KEY) || '{}');
                set({ favorites });
            } catch (e) {
                console.error(`Error reading favorites from storage: ${e}`);
            }
        },
    })),
);
