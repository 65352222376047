import { useEffect, useMemo, useState } from 'react';
import { StyledCompareActions, StyledCompareOverview, StyledCompareOverviewWrapper } from './Style';
import { CompareProductCards } from './CompareProductCards';
import { Button, MaxWidth, Modal, Portal, useTracking } from '~/shared/components';
import { CompareOverlay } from '../compareOverlay/CompareOverlay';
import { getCompareProductsLength, useCompareStore } from '../../hooks';
import { usePage } from '~/templates/pages';
import { AnimatePresence } from 'framer-motion';
import { useTranslation } from '~/shared/utils';
import { countryCodeKey, getCountryName } from '~/lib/twoLetterIsoCodeCountryList';

export const CompareOverview = () => {
    const [showOverview, setShowOverview] = useState(false);
    const { translate } = useTranslation();

    const { market = 'int', culture = 'en' } = usePage();
    const currentLocale = useMemo(() => `${culture}-${market?.toUpperCase()}`, [market, culture]);

    const {
        compareProducts,
        clearCompareProduct,
        handleCloseOverlay,
        handleOpenOverlay,
        showOverlay,
        locale,
    } = useCompareStore();

    const compareProductsLength = getCompareProductsLength(compareProducts);
    const { trackFavoritesProductsCompared } = useTracking();

    useEffect(() => {
        if (currentLocale !== locale) {
            clearCompareProduct(currentLocale);
        }
    }, [currentLocale, locale]);

    useEffect(() => {
        if (compareProductsLength > 0) {
            setShowOverview(true);
        } else {
            setShowOverview(false);
        }
    }, [compareProductsLength]);

    return (
        <>
            <Portal>
                <AnimatePresence>
                    {showOverview && (
                        <StyledCompareOverview
                            transition={{
                                duration: 0.4,
                                ease: 'easeInOut',
                            }}
                            exit={{ opacity: 0, scale: 0.5, bottom: '-50%' }}
                        >
                            <MaxWidth>
                                <StyledCompareOverviewWrapper>
                                    {compareProducts.map((product, idx) => (
                                        <CompareProductCards
                                            key={product?.productId || idx}
                                            product={product}
                                            idx={idx}
                                        />
                                    ))}
                                    <StyledCompareActions>
                                        <Button
                                            disabled={compareProductsLength === 0}
                                            onClick={() => clearCompareProduct()}
                                            variant="Plain"
                                        >
                                            {translate(
                                                'Kompan.Commerce.CompareOverviewClearButton',
                                            )}
                                        </Button>
                                        <Button
                                            disabled={compareProductsLength < 2}
                                            onClick={() => {
                                                handleOpenOverlay();
                                                trackFavoritesProductsCompared({
                                                    country:
                                                        (market &&
                                                            getCountryName(
                                                                market as countryCodeKey,
                                                            )) ||
                                                        market,
                                                    comparison_count: `${compareProductsLength} / 3`,
                                                    product1_name:
                                                        compareProducts?.[0]?.displayName || '',
                                                    product1_id:
                                                        compareProducts?.[0]?.productId || '',
                                                    product1_cateogry:
                                                        compareProducts?.[0]?.categoryPaths?.[0]
                                                            ?.pathFromRoot?.[0]?.displayName || '',
                                                    product1_cateogry2:
                                                        compareProducts?.[0]?.categoryPaths?.[0]
                                                            ?.pathFromRoot?.[1]?.displayName || '',
                                                    product1_cateogry3:
                                                        compareProducts?.[0]?.categoryPaths?.[0]
                                                            ?.pathFromRoot?.[2]?.displayName || '',

                                                    product2_name:
                                                        compareProducts?.[1]?.displayName || '',
                                                    product2_id:
                                                        compareProducts?.[1]?.productId || '',
                                                    product2_cateogry:
                                                        compareProducts?.[1]?.categoryPaths?.[0]
                                                            ?.pathFromRoot?.[0]?.displayName || '',
                                                    product2_cateogry2:
                                                        compareProducts?.[1]?.categoryPaths?.[0]
                                                            ?.pathFromRoot?.[1]?.displayName || '',
                                                    product2_cateogry3:
                                                        compareProducts?.[1]?.categoryPaths?.[0]
                                                            ?.pathFromRoot?.[2]?.displayName || '',

                                                    product3_name:
                                                        compareProducts?.[1]?.displayName || '',
                                                    product3_id:
                                                        compareProducts?.[1]?.productId || '',
                                                    product3_cateogry:
                                                        compareProducts?.[1]?.categoryPaths?.[0]
                                                            ?.pathFromRoot?.[0]?.displayName || '',
                                                    product3_cateogry2:
                                                        compareProducts?.[1]?.categoryPaths?.[0]
                                                            ?.pathFromRoot?.[1]?.displayName || '',
                                                    product3_cateogry3:
                                                        compareProducts?.[1]?.categoryPaths?.[0]
                                                            ?.pathFromRoot?.[2]?.displayName || '',
                                                });
                                            }}
                                            variant="Red"
                                        >
                                            {translate(
                                                'Kompan.Commerce.CompareOverviewCompareButton',
                                            )?.replace('[x]', `(${compareProductsLength}/3)`)}
                                        </Button>
                                    </StyledCompareActions>
                                </StyledCompareOverviewWrapper>
                            </MaxWidth>
                        </StyledCompareOverview>
                    )}
                </AnimatePresence>
            </Portal>
            <Modal onDismiss={handleCloseOverlay} open={showOverlay}>
                <CompareOverlay />
            </Modal>
        </>
    );
};
