import { useEffect, useRef } from 'react';
import { StyledOptionsContent, StyledOptionsWrap } from '../styled';
import { Compare } from '../../compare';
import { ProductOptions } from '../ProductOptions';
import { stagger, useAnimate, usePresence } from 'framer-motion';
import { Favorite } from '../../favorite';
import { useFrame, useTranslation } from '~/shared/utils';
import { CompareButton } from '~/shared/components/CompareButton';
import { FavoriteButton } from '~/shared/components/FavoriteButton';

type Popover = ProductOptions;

export const Popover = ({ variant, product, products }: Popover) => {
    const [isPresent, safeToRemove] = usePresence();
    const [scope, animate] = useAnimate();
    const { translate } = useTranslation();
    const listRef = useRef<HTMLUListElement>(null);
    const { isMyKompan } = useFrame();

    useEffect(() => {
        const listHeight = listRef.current?.scrollHeight;

        if (isPresent) {
            const enterAnimation = async () => {
                await animate(
                    'ul',
                    {
                        opacity: 1,
                        height: listHeight ? `${listHeight + 26}px` : '120px',
                        filter: 'blur(0px)',
                    },
                    {
                        duration: 0.3,
                    },
                );
                await animate(
                    'ul > li',
                    { opacity: 1, filter: 'blur(0px)' },
                    {
                        duration: 0.2,
                        delay: stagger(0.2, { startDelay: -0.2 }),
                    },
                );
            };
            enterAnimation();
        } else {
            const exitAnimation = async () => {
                await animate(
                    'ul > li',
                    { opacity: 0, filter: 'blur(0.5px)' },
                    {
                        duration: 0.2,
                        delay: 0,
                    },
                );
                await animate(
                    'ul',
                    { opacity: 0, height: '20px', filter: 'blur(2px)' },
                    {
                        duration: 0.3,
                    },
                );

                safeToRemove();
            };

            exitAnimation();
        }
    }, [isPresent]);

    return (
        <StyledOptionsContent ref={scope}>
            <StyledOptionsWrap variant={variant} ref={listRef}>
                <li>
                    <Compare productId={product.productId || undefined} products={products}>
                        {({ defaultText, isActive, handleClick }) => (
                            <CompareButton
                                isPressed={isActive}
                                onClick={handleClick}
                                text={defaultText}
                            />
                        )}
                    </Compare>
                </li>
                {!isMyKompan && (
                    <li>
                        <Favorite product={product}>
                            {({ isActive, handleClick }) => (
                                <FavoriteButton
                                    is_active={isActive}
                                    onClick={handleClick}
                                    showText
                                    text={translate('Kompan.Commerce.FavoriteButton')}
                                />
                            )}
                        </Favorite>
                    </li>
                )}
            </StyledOptionsWrap>
        </StyledOptionsContent>
    );
};
