import Heart from '$icons/heart.svg';
import { Text } from '~/shared/components';
import { Icon } from '../Icon';
import { StyledFavoriteButton } from './styled';
type FavoriteButton = {
    onClick: () => void;
    is_active: boolean;
    showText?: boolean;
    text?: string;
};

export const FavoriteButton = (props: FavoriteButton) => {
    const { onClick, is_active, showText = false, text } = props;

    return (
        <StyledFavoriteButton suppressHydrationWarning onClick={onClick} isActive={is_active}>
            <Icon size="md">
                <Heart />
            </Icon>
            {/* </button> */}
            {showText && <Text variant="captionSm">{text}</Text>}
        </StyledFavoriteButton>
    );
};
