import styled from '@emotion/styled';
import { ifProp } from 'styled-tools';

export const StyledFavoriteButton = styled.button<{ isActive: boolean }>(
    ({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
        background: 'none',
        cursor: 'pointer',
        margin: 0,
        padding: 2,
        border: 'none',
        gap: theme.spaces[2],
        color: theme.colors.black100,
    }),
    ifProp({ isActive: true }, () => ({
        'svg path': {
            fill: 'currentColor',
        },
    })),
);
